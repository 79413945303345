import React, { useEffect, useState } from "react";
import "./styles.css";

type BallType = "CURRENT" | "NORMAL" | "GREY";

interface BallProps {
  pageNumber?: number;
  type: BallType;
  onClick?: Function;
}

function BallComponent(props: BallProps) {
  return (
    <button
      onClick={() => {
        props.onClick?.(props.pageNumber);
      }}
      className={
        "ball__button " +
        (props.type === "CURRENT"
          ? "ball__current"
          : props.type === "NORMAL"
          ? "ball__normal"
          : "ball__grey")
      }
    >
      {props.type === "GREY" ? "..." : <>{props.pageNumber}</>}
    </button>
  );
}

export interface PaginationProps {
  totalPages: number;
  currentPage: number;
  onClick: Function;
}

function Pagination(props: PaginationProps) {
  function generateMiddleBalls(currentPage: number, totalPages: number) {
    let needToBeGenerated = totalPages <= 7 ? totalPages - 2 : 3;
    let balls = [];
    let startBallNumber = 0;

    if (totalPages <= 7) {
      startBallNumber = 2;
    } else if (currentPage === 2) {
      startBallNumber = 3;
    } else if (currentPage === totalPages - 1 || currentPage === totalPages) {
      startBallNumber = totalPages - 4;
    } else if (currentPage > 3 && currentPage < totalPages - 2) {
      startBallNumber = currentPage - 1;
    } else if (currentPage === totalPages - 2) {
      startBallNumber = currentPage - 2;
    } else {
      startBallNumber = 3;
    }

    for (let index = 0; index < needToBeGenerated; index++) {
      balls.push(
        <BallComponent
          key={index}
          type={
            props.currentPage === index + startBallNumber ? "CURRENT" : "NORMAL"
          }
          pageNumber={index + startBallNumber}
          onClick={
            props.currentPage === index + startBallNumber
              ? undefined
              : props.onClick
          }
        />
      );
    }

    return <>{balls}</>;
  }
  return (
    <div className="pagination__container">
      <BallComponent
        type={props.currentPage === 1 ? "CURRENT" : "NORMAL"}
        pageNumber={1}
        onClick={props.currentPage === 1 ? undefined : props.onClick}
      />
      {props.totalPages < 8 ? null : (
        <BallComponent
          type={
            props.currentPage > 4
              ? "GREY"
              : props.currentPage === 2
              ? "CURRENT"
              : "NORMAL"
          }
          pageNumber={2}
          onClick={props.currentPage === 2 ? undefined : props.onClick}
        />
      )}

      {generateMiddleBalls(props.currentPage, props.totalPages)}

      {props.totalPages < 8 ? null : (
        <BallComponent
          type={
            props.currentPage <= props.totalPages - 4
              ? "GREY"
              : props.currentPage === 2
              ? "CURRENT"
              : "NORMAL"
          }
          pageNumber={props.totalPages - 1}
          onClick={
            props.currentPage === props.totalPages - 1
              ? undefined
              : props.onClick
          }
        />
      )}

      {props.totalPages > 1 ? (
        <BallComponent
          type={props.currentPage === props.totalPages ? "CURRENT" : "NORMAL"}
          pageNumber={props.totalPages}
          onClick={
            props.currentPage === props.totalPages ? undefined : props.onClick
          }
        />
      ) : null}
    </div>
  );
}
export default Pagination;
