export default class Constants {
  static readonly DEFAULT_PASSWORD = "352514";

  // MESSAGENS
  static readonly MESSAGE_ALERT_CREATEUSER = "Senha padrão enviada com sucesso:";
  static readonly MESSAGE_UNIQUE_PHONE = "Este número já está sendo utilizado";
  static readonly MESSAGE_UNIQUE_INITIALS = "Já existe uma Fazenda com essa SIGLA";
  static readonly MESSAGE_INVALID_MAP =
    "Erro ao importar o mapa, verifique o conteúdo interno do mapa e tente novamente!";
  static readonly MESSAGE_INVALID_WEIGHT = "Valor informado inválido, informe o valor partir de 30 kg.";
  static readonly MESSAGE_REQUIRED_FIELD = "*Campo obrigatório!.";
  static readonly MESSAGE_DISCARD_CHANGES = "Ao cancelar, você perde todas as edições realizadas na tabela!";
  static readonly MESSAGE_UNIQUE_INITIALS_MICROAREA = "Sigla do pasto já está castrado.";
  static readonly MESSAGE_MAPPING_NOT_FOUND = "Mapeamento geográfico do retiro não realizado.";
  static readonly MESSAGE_MAPPING_MICROAREA_NOT_FOUND = "Mapeamento geográfico do pasto não realizado.";
  static readonly MESSAGE_MICROAREA_NOT_FILLED = "Campo obrigatório não preenchido no Pasto ";
  static readonly MESSAGE_AREA_UNVAILABLE_ACREAGE = "Foi ultrapassado o limite de área total disponível";

  //Modal
  static readonly MESSAGE_INVALID_MODAL_TITLE = "Algo deu errado.";
  static readonly MESSAGE_INVALID_MODAL_FILTER = "Erro ao carregar o filtro, tente novamente!";
  static readonly MESSAGE_MODAL_ALERT_TITLE = "Você tem certeza?";

  //STORAGE
  static readonly LOCALSTORAGE_PAYLOAD = "@pastejo-onboarding/payload";

  // DADOS
  static readonly MONTHS_SHORT_NAME = {
    0: "Jan",
    1: "Fev",
    2: "Mar",
    3: "Abr",
    4: "Mai",
    5: "Jun",
    6: "Jul",
    7: "Ago",
    8: "Set",
    9: "Out",
    10: "Nov",
    11: "Dez",
  };

  static readonly MONTHS_FULL_NAME = {
    0: "Janeiro",
    1: "Fevereiro",
    2: "Março",
    3: "Abril",
    4: "Maio",
    5: "Junho",
    6: "Julho",
    7: "Agosto",
    8: "Setembro",
    9: "Outubro",
    10: "Novembro",
    11: "Dezembro",
  };

  // ROUTES

  static readonly LOGIN = "/entrar";
  static readonly RESET_PASSWORD = "/novasenha";
  static readonly ONBOARDING = "/bemvindo/onboarding";
  static readonly CATTLE_MAP = "/mapadegado";
  static readonly PADDOCKS_MAP = "/mapadepastos";
  static readonly EVALUATION_REPORT = "/relatorio";

  static readonly PADDOCKS_MAP_DASHBOARD_CATTLE_MAP = "/mapadepastos/dashboard/mapadegado";
  static readonly PADDOCKS_MAP_DASHBOARD_SUPPLEMENTATION = "/mapadepastos/dashboard/suplementacao";
  static readonly PADDOCKS_MAP_PADDOCK_TIME = "/mapadepastos/tempomaximo";
  static readonly PADDOCKS_MAP_GRAZING_ROUTES = "/mapadepastos/rotasdepastejo";
  static readonly PADDOCKS_MAP_OCCUPATION = "/mapadepastos/ocupacao";
  static readonly PADDOCKS_MAP_CAPACITY = "/mapadepastos/lotacao";

  static readonly MASTER_ROUTES = [
    Constants.ONBOARDING,
    Constants.PADDOCKS_MAP_DASHBOARD_CATTLE_MAP,
    Constants.PADDOCKS_MAP_DASHBOARD_SUPPLEMENTATION
  ]

  static readonly GERENTE_ROUTES = [
    Constants.PADDOCKS_MAP_DASHBOARD_CATTLE_MAP,
    Constants.PADDOCKS_MAP_DASHBOARD_SUPPLEMENTATION,
  ]

  static readonly PERMISSION_ROUTES = [
    ...Constants.MASTER_ROUTES,
    ...Constants.GERENTE_ROUTES
  ]

  // TITLE PAGES

  static readonly TITLE_DASHBOARD = "Pastejo | Dashboard";
  static readonly TITLE_PASTURE_EVALUATION = "Pastejo | Avaliação de Pastagem";
  static readonly TITLE_PADDOCKS_MAP_INDEX = "Pastejo | Mapa de Pastos";
  static readonly TITLE_PADDOCKS_MAP_ROUTES = "Pastejo | Rotas de Pastejo";
  static readonly TITLE_PADDOCKS_MAP_CAPACITY = "Pastejo | Lotação por Rota";
  static readonly TITLE_PADDOCKS_MAP_OCCUPATION = "Pastejo | Ocupação dos Pastos";
  static readonly TITLE_PADDOCKS_MAP_PADDOCK_TIME = "Pastejo | Tempo no Pasto";

  // MAPS CONFIGS POLYGONS

  static readonly POLYGON_YELLOW = {
    strokeColor: "#FF9501",
    fillColor: "rgba(255, 149, 1, 0.5)",
    strokeOpacity: 1,
    fillOpacity: 0.5,
    strokeWeight: 3,
    zIndex: 2
  };

  static readonly POLYGON_NEW_POLYGON = {
    strokeColor: "#FFFFFF",
    fillColor: "rgba(97, 255, 246, 0.5)",
    strokeOpacity: 1,
    fillOpacity: 0.5,
    strokeWeight: 3,
  };

  static readonly POLYGON_MAIN = {
    strokeColor: "#FFFFFF",
    fillColor: "rgba(87, 206, 113, 0.5)",
    strokeOpacity: 1,
    fillOpacity: 1,
    strokeWeight: 1,
  };
  
  static readonly POLYGON_SELECTED = {
    strokeColor: "#FFFFFF",
    fillColor: "rgba(97, 255, 246, 0.5)",
    strokeOpacity: 1,
    fillOpacity: 1,
    strokeWeight: 1,
  };

  static readonly POLYGON_NOT_SELECTED = {
    strokeColor: "#FFFFFF",
    fillColor: "rgba(255, 255, 255, 0.3)",
    strokeOpacity: 1,
    fillOpacity: 1,
    strokeWeight: 1
  };

  static readonly POLYGON_STROKE = {
    strokeColor: "#FFFFFF",
    fillColor: "#FFFFFF",
    strokeOpacity: 1,
    fillOpacity: 0.3,
    strokeWeight: 3,
    zIndex: 1
  };

  static readonly POLYGON_TRANSLUCID = {
    strokeColor: "#FFFFFF",
    fillColor: "#FFFFFF",
    strokeOpacity: 0.1,
    fillOpacity: 0.2,
    strokeWeight: 1,
  };

  static readonly POLYLINE_DASHED_GREEN = {
    strokeOpacity: 0,
    fillOpacity: 0,
    zIndex: 3,
    icons: [
      {
        icon: {
          path: "M 0,2 0,-1",
          strokeOpacity: 1,
          scale: 3,
          strokeColor: "#57CE71",
        },
        offset: "50",
        repeat: "19px",
      },
    ],
  };

  // React Leaflet Configs
  static readonly LEAFLET_DASHED_GREEN = {
    weight: 3.5,
    color: "#57CE71",
    fill: false,
    dashArray: "30, 20",
    dashOffset: "50",
  };

  static readonly LEAFLET_NORMAL_BLUE = {
    weight: 2,
    color: "#FFFFFF",
    fill: true,
    fillColor: "#61FFF6",
    fillOpacity: 0.5,
  };

  static readonly LEAFLET_VIEW_BATCHES = {
    weight: 2,
    color: "rgba(255, 255, 255, 0.4)",
    fill: true,
    fillColor: "#61FFF6",
    fillOpacity: 0,
  };

  static readonly LEAFLET_MICRO_AVAILABLE = {
    weight: 2,
    color: "rgb(255, 255, 255)",
    fill: true,
    fillColor: "#FFFFFF",
    fillOpacity: 0.3,
  };

  static readonly LEAFLET_UNFOCUS_TRANSPARENT = {
    weight: 2,
    color: "rgba(255, 255, 255, 0.4)",
    fill: false,
  };

  static readonly LEAFLET_UNFOCUS_TRANSLUCENT = {
    weight: 2,
    color: "#FFFFFF",
    fill: true,
    fillColor: "#FFFFFF",
    fillOpacity: 0.3,
  };

  static readonly LEAFLET_UNFOCUS = {
    weight: 2,
    color: "rgba(255, 255, 255, 0.4)",
    fill: true,
    fillColor: "#FFFFFF",
    fillOpacity: 0.1,
  };

  static readonly DEFAULT_COORDS = { lat: -16.0738564, lng: -45.0728955 };

  static readonly CATEGORY_TYPE = {
    femea: "FEMALE",
    macho: "MALE",
    getDescription: (TYPE: string) => {
      const TYPES = { FEMALE: "Fêmea", MALE: "Macho" };
      //@ts-ignore
      return TYPES[TYPE];
    },
  };

  //Colors

  // Grazing Map
  static readonly GRAZINGMAP_COLORS = {
    GREEN: "#57CE71",
    CYAN: "#61FFF6",
    ORANGE: "#FF9501",
    RED: "#FF6161",
    YELLOW: "#FFCA80",
    PURPLE: "#7B61FF",
    ORANGEHIGH: "#ff4b00",
    BLUE: "#0157FF",
  };

  // Capacity Route
  static readonly CAPACITYROUTE_COLORS = {
    WHITE: "#ffffff",
    YELLOW: "#ffca80",
    ORANGE: "#ff9501",
    ORANGEHIGH: "#ff4b00",
    RED: "#ff0000",
  };

  // Pasture Occupation
  static readonly PASTUREOCCUPATION_COLORS = {
    ORANGE: "#FF9501",
    BLUE: "#0157FF",
    WHITE: "#FFFFFF",
  };

  static readonly PADDOCKTIME_COLORS = {
    GREEN: "#57CE71",
    ORANGE: "#FF9501",
    RED: "#FF6161",
    BLUE: "#0157FF",
    WHITE: "#ffffff",
  };

  // Opções de dias para tempo com e sem gado
  static readonly DAYS_OPTIONS = [
    { label: "15 Dias", value: 15 },
    { label: "30 Dias", value: 30 },
    { label: "45 Dias", value: 45 },
    { label: "60 Dias", value: 60 },
    { label: "90 Dias", value: 90 },
  ];

  static readonly LEAFLET_DEFAULT_ZOOM_POLYGONS = 15;
  static readonly LEAFLET_ZOOM_MIN = 5;
  static readonly LEAFLET_ZOOM_MAX = 17;
  // estados de apresentação pedidos nos protótipos
  static readonly LEAFLET_ZOOM_4X = 17;
  static readonly LEAFLET_ZOOM_3X = 15;
  static readonly LEAFLET_ZOOM_2X = 10;
  static readonly LEAFLET_ZOOM_1X = 9;

  // Isso ajuda no cálculo da diferença de exibição dos níveis de cada fazenda
  static readonly LEAFLET_ZOOM_BOUNDS_DIFFERENCE_4X = 0; // diferença entre o zoom máximo da fazenda
  static readonly LEAFLET_ZOOM_BOUNDS_DIFFERENCE_3X = 1; // diferença entre o zoom máximo da fazenda
  static readonly LEAFLET_ZOOM_BOUNDS_DIFFERENCE_2X = 2; // diferença entre o zoom máximo da fazenda
  static readonly LEAFLET_ZOOM_BOUNDS_DIFFERENCE_1X = 4; // diferença entre o zoom máximo da fazenda
  // diferença entre fazendas para habilitar o zoom, menores que 5km não existe animação
  static readonly LEAFLET_MINIMAL_DISTANCE_TO_ZOOM = 5000;
  static readonly LEAFLET_FACTOR_DISTANCE = 100000; // 1s a cada 100km
  static readonly LEAFLET_MAX_TIME_DISTANCE_ANIMATION = 8; // 1s a cada 100km

  // Nomes dos componentes de zoom que são utilizados para separação no componente principal
  static readonly COMPONENT_1X_NAME = "ContainerZoom1X";
  static readonly COMPONENT_2X_NAME = "ContainerZoom2X";
  static readonly COMPONENT_3X_NAME = "ContainerZoom3X";
  static readonly COMPONENT_4X_NAME = "ContainerZoom4X";

  static readonly STATUS_CODE_OK = 200;

  static readonly PROFILE_CODE_CAPATAZ = 4;
}
