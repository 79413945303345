import { Navigate, Route, Routes } from "react-router-dom";
import Constants from "../constants/Index";
import { FilterContextProvider } from "../contexts/FilterContext";
import { OnboardingProvider } from "../contexts/OnboardingContext";
import { Onboarding } from "../pages/Onboarding/Template/Index";
import { CapacityByRoute } from "../pages/PaddocksMap/Maps/CapacityByRoute/Index";
import { Index } from "../pages/PaddocksMap/Maps/DefaultMaps/Index";
import { GrazingRoutes } from "../pages/PaddocksMap/Maps/GrazingRoutes/Index";
import { PaddockTime } from "../pages/PaddocksMap/Maps/PaddockTime/Index";
import { PastureOccupation } from "../pages/PaddocksMap/Maps/PastureOccupation/Index";
import { PaddocksMapIndex } from "../pages/PaddocksMap/Template";
import { isAuthenticated } from "../services/auth";
import { TaskCreationProvider } from "../contexts/TaskCreationContext";
import Dashboard from "../pages/Dashboard";
import { PdfPage } from "../pages/Pdf";

type AuthRoutesProps = {
  mapLoad: boolean;
};

interface PropType {
  component: React.FC;
}

const PrivateRoute = ({ component: Component }: PropType) => {
  if (isAuthenticated()) return <Component />;
  return <Navigate to="/entrar" />;
};

export function AuthRoutes({ mapLoad }: AuthRoutesProps) {
  return (
    <>
      {/* [INICIO] Onboarding */}
      <OnboardingProvider>
        <Routes>
          <Route path="bemvindo">
            <Route
              path={Constants.ONBOARDING}
              element={<PrivateRoute component={Onboarding}></PrivateRoute>}
            />
          </Route>
        </Routes>
      </OnboardingProvider>
      {/* [FIM] Onboarding */}

      <FilterContextProvider>
        <OnboardingProvider>
          <TaskCreationProvider>
            <Routes>
              <Route path={Constants.EVALUATION_REPORT} element={<PdfPage />} />
              
              <Route path={Constants.PADDOCKS_MAP} element={<PrivateRoute component={PaddocksMapIndex} />}>
                <Route path="" element={<Index />} />
                <Route path={Constants.PADDOCKS_MAP_OCCUPATION} element={<PastureOccupation />} />
                <Route path={Constants.PADDOCKS_MAP_GRAZING_ROUTES} element={<GrazingRoutes />} />
                <Route path={Constants.PADDOCKS_MAP_CAPACITY} element={<CapacityByRoute />} />
                <Route path={Constants.PADDOCKS_MAP_PADDOCK_TIME} element={<PaddockTime />} />
                <Route path={Constants.PADDOCKS_MAP_DASHBOARD_CATTLE_MAP} element={<Dashboard url={`${process.env.REACT_APP_GRAFANA_BASE_URL}${process.env.REACT_APP_GRAFANA_DASHBOARD_CATTLE_MAP_URL}`} />} />
                <Route path={Constants.PADDOCKS_MAP_DASHBOARD_SUPPLEMENTATION} element={<Dashboard url={`${process.env.REACT_APP_GRAFANA_BASE_URL}${process.env.REACT_APP_GRAFANA_DASHBOARD_SUPPLEMENTATION_URL}`} />} />
              </Route>
            </Routes>
          </TaskCreationProvider>
        </OnboardingProvider>
      </FilterContextProvider>
    </>
  );
}
