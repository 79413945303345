import React, { useEffect, useRef, useState } from "react";
import "./style.css";
import { Modal } from "../Default/Index";
import { RoundedCloseButton } from "../../Buttons/RoundedCloseButton/Index";
import { ReactComponent as PointIcon } from "../../../assets/svg/icons/point.svg";
import {
  DropDownFilterRefProps,
  DropDownSelect,
} from "../../Filters/DropDown/Index";
import {
  DropDownSearch,
  DropDownSearchFilterRefProps,
} from "../../Filters/DropDownSearch/Index";
import { ButtonOnlyTitle } from "../../Buttons/ButtonOnlyTitle/Index";
import { useFilter } from "../../../hooks/useFilter";

import axios from "../../../services/axios";
import { IFarms } from "../../../@types/API/IFarm";
import Constants from "../../../constants/Index";
import { getFarmsAsOptions } from "../../../utils/farms/farmsAsOptions";
import {
  IOptionProps,
  IOptionsProps,
} from "../../../@types/reactSelect/IOption";
import { IAreas } from "../../../@types/API/IArea";
import { getAreasAsOptions } from "../../../utils/farms/areasAsOptions";
import { sortBy } from "../../../utils/arrays/sortBy";
import { getMicroAreasAsOptions } from "../../../utils/farms/microAreasAsOptions";
import { IMicroAreas } from "../../../@types/API/IMicroArea";
import { ICoords } from "../../../@types/GoogleMaps/ICoord";
import { StaticMapRender } from "../../EditOnboarding/components/StaticMapRender/Index";
import { useTaskCreation } from "../../../hooks/useTaskCreation";
import { DrawMapElement } from "../../Tasks/DrawOnMap";
import { useEditOnboarding } from "../../../hooks/useEditOnboarding";

interface CreateTaskModalProps {
  visible: boolean;
  onClose: () => void;
  task: ITask;
  children: JSX.Element | "NO_PAGE";
  onGoBack: () => void;
}

export const CreateTaskModal: React.FC<CreateTaskModalProps> = ({
  visible,
  onClose,
  task,
  children,
  onGoBack,
}) => {
  const { farmFilter, areaFilter, microAreaDefaultOption, microAreaFilter, setSystemManager } =
    useFilter();

  const [allAreas, setAllAreas] = useState<IAreas>();
  const [allMicroAreas, setAllMicroAreas] = useState<IMicroAreas>();

  const [selectedFarm, setSelectedFarm] = useState<any>();
  const [selectedArea, setSelectedArea] = useState<any>();
  const [selectedMicroArea, setSelectedMicroArea] = useState<any>();
  const [selectedResponsible, setSelectedResponsible] = useState<any>();

  const [farmOptions, setFarmOptions] = useState<IOptionsProps>();
  const [areaOptions, setAreaOptions] = useState<IOptionsProps>();
  const [microAreaOptions, setMicroAreaOptions] = useState<IOptionsProps>();
  const [responsibleOptions, setResponsibleOptions] = useState<IOptionsProps>();

  const [currentFarm, setCurrentFarm] = useState<IOptionProps>();
  const [currentArea, setCurrentArea] = useState<IOptionProps>();
  const [currentMicroArea, setCurrentMicroArea] = useState<IOptionProps>();
  const [currentResponsible, setCurrentResponsible] = useState<IOptionProps>();

  const [allFieldsFilled, setAllFieldsFilled] = useState<boolean>(false);
  const [taskPage, setTaskPage] = useState<boolean>(false);

  const [allResponsibles, setAllResponsibles] = useState<IOptionsProps>();
  const [taskDetails, setTaskDetails] = useState<string>("");

  const farmRef = useRef<DropDownFilterRefProps>();
  const areaRef = useRef<DropDownFilterRefProps>();
  const responsibleRef = useRef<DropDownFilterRefProps>();
  const microAreaRef = useRef<DropDownSearchFilterRefProps>();

  const { color, dashArray } = Constants.LEAFLET_DASHED_GREEN;
  const staticsElementsOptions = { color, dashArray };

  const {
    allFarms,
    resetContext,
    setFarmId,
    setAreaId,
    setResponsibleId,
    setMicroAreaId,
    createTask,
    allowTaskCreation,
    taskCreatedSuccessfully,
    taskCreationError,
    setPayload,
    payload,
    setContextMicroArea,
    setAllAreas: setAreasContext,
    setAllMicroAreas: setMicroAreasContext,
    showDrawOnMapModal,
    currentCoords,
    setCurrentCoords,
    contextMicroArea,
    editFences
  } = useTaskCreation();

  const [drawCoords, setDrawCoords] = useState<ICoords | undefined>(undefined);
  const [fitCoords, setFitCoords] = useState<ICoords>([]);
  const [needProduct, setNeedProduct] = useState(false);

  useEffect(() => {
    initFarms(allFarms);
    initFields();
  }, []);

  useEffect(() => {
    if (currentFarm) {
      const farm = allFarms?.filter(farm => farm.id == currentFarm.value)[0]
      setFitCoords(farm?.map_coords || [])
    }
  }, [currentFarm]);

  useEffect(() => {
    if (currentArea && allAreas) {
      const area = allAreas?.filter(area => area.id == currentArea.value)[0]
      setFitCoords(area?.map_coords || [])
    }
  }, [currentArea, allAreas]);

  useEffect(() => {
    if (currentMicroArea && allMicroAreas) {
      const microArea = allMicroAreas?.filter(microArea => microArea.id == currentMicroArea.value)[0]
      setFitCoords(microArea?.map_coords || [])
    }
  }, [currentMicroArea, allMicroAreas]);

  useEffect(() => {
    if (!taskCreatedSuccessfully) return;

    closeModal();
  }, [taskCreatedSuccessfully]);

  useEffect(() => {
    if (!taskCreationError) return;

    closeModal();
  }, [taskCreationError]);

  const initFields = () => {
    if (farmFilter) {
      setCurrentFarm({
        label: `${farmFilter.name} (${farmFilter.initials})`,
        value: farmFilter.id,
      });
      setFitCoords(farmFilter?.coords)
    }
    if (areaFilter) {
      setCurrentArea({
        label: `${areaFilter.name} (${areaFilter.initials})`,
        value: areaFilter.id,
      });
      setFitCoords(areaFilter?.coords)
    }
    if (microAreaDefaultOption) {
      setCurrentMicroArea(microAreaDefaultOption);
      setCurrentCoords(microAreaFilter.coords);
      setFitCoords(microAreaFilter?.coords)
      setDrawCoords(microAreaFilter?.coords)
    }
  };

  const initFarms = (data: any) => {
    const farmArray: IFarms = data;
    const options = getFarmsAsOptions(farmArray);
    setFarmOptions(options);
    if (farmFilter) {
      setSelectedFarm(farmArray.find((farm) => farm.id === farmFilter.id));
    }
  };

  const handleFarmChange = (id: any) => {
    setCurrentArea(undefined);
    setCurrentResponsible(undefined);
    setCurrentMicroArea(undefined);
    setCurrentCoords([]);
    setDrawCoords(undefined)
    setSelectedResponsible(undefined);
    setMicroAreaOptions([]);

    if (areaRef && areaRef.current) areaRef.current.resetDropDown();
    if (responsibleRef && responsibleRef.current)
      responsibleRef.current.resetDropDown();
    if (microAreaRef && microAreaRef.current)
      microAreaRef.current?.resetDropDownFilter();
    setCurrentFarm(farmOptions?.find((farm) => farm.value == id));
    setSelectedFarm(allFarms?.find((farm) => farm.id === id) ?? undefined);
  };

  useEffect(() => {
    if (!currentFarm) return;

    getAreas();
    getMicroAreas();
  }, [currentFarm]);

  const getAreas = () => {
    if (!currentFarm) return;

    axios
      .get(`/areas`, { params: { farm_id: currentFarm.value } })
      .then((response) => {
        if (response.status === Constants.STATUS_CODE_OK)
          initAreas(response.data);
      });
  };

  const initAreas = (data: any) => {
    const areaArray: IAreas = data;
    const options = getAreasAsOptions(areaArray).sort(sortBy("label"));
    const allResponsibles = areaArray.map((area) => ({
      label: area.manager_user?.name,
      value: area.manager_user?.id,
    })) as IOptionsProps;

    setAllResponsibles(allResponsibles);
    setAllAreas(areaArray);
    setAreasContext(areaArray);
    setAreaOptions(options);
    if (areaFilter) {
      const area = areaArray.find((area) => area.id === areaFilter.id);
      const responsible = {
        label: area?.manager_user?.name ?? "",
        value: area?.manager_user?.id,
      };
      setCurrentResponsible(responsible);
      setResponsibleOptions([responsible]);
      setSelectedArea(area);
    }
  };

  const handleAreaChange = (id: any) => {
    setCurrentResponsible(undefined);
    setCurrentMicroArea(undefined);
    setCurrentCoords([]);
    setDrawCoords(undefined)
    setMicroAreaOptions([]);

    if (responsibleRef && responsibleRef.current)
      responsibleRef.current.resetDropDown();
    if (microAreaRef && microAreaRef.current)
      microAreaRef.current?.resetDropDownFilter();
    setCurrentArea(areaOptions?.find((area) => area.value == id));
    setSelectedArea(allAreas?.find((area) => area.id === id) ?? undefined);
  };

  useEffect(() => {
    if (!currentArea) return;

    initResponsible();
    setMicroAreaOptions(microAreaOptionsFiltered());
  }, [currentArea]);

  const initResponsible = () => {
    if (!currentArea || !allAreas) return;

    const { name, id } = allAreas.find((area) => area.id === currentArea.value)
      ?.manager_user!;

    if (!name || !id) return;
    setCurrentResponsible({
      label: name,
      value: id,
    });

    setResponsibleOptions([
      {
        label: name,
        value: id,
      },
    ]);
  };

  const getMicroAreas = () => {
    if (!currentFarm || task.type === "DETACHED_TASK" || task.type === "PRODUCT_INCOMING") return;

    axios
      .get("micro-areas", {
        params: {
          farm_id: currentFarm.value,
        },
      })
      .then((response) => {
        if (response.status === Constants.STATUS_CODE_OK) {
          setAllMicroAreas(response.data);
          setMicroAreasContext(response.data)
        }
      });
  };

  function microAreaOptionsFiltered(): IOptionsProps {
    if (!currentArea || !microAreaOptions || !allMicroAreas) return [];

    let label = `${selectedFarm.initials || ""}${selectedArea ? `-${selectedArea.initials}-` : "-"
      }`;
    const filtered = allMicroAreas.filter(
      (el) => el.area_id === currentArea.value
    );
    return getMicroAreasAsOptions(filtered, currentArea?.value, label);
  }

  const handleMicroAreaChange = (id: any) => {
    microAreaRef.current?.resetDropDownFilter()
    const microArea = allMicroAreas?.find((mA) => mA.id === id);
    setCurrentCoords(microArea?.map_coords || []);
    setDrawCoords(microArea?.map_coords)
    setCurrentMicroArea(
      microAreaOptionsFiltered().find((microArea) => microArea.value === id)
    );
    setSelectedMicroArea(microArea);
  };

  function getFarmLabel(): string {
    return farmFilter
      ? farmFilter.initials
      : allFarms?.find((farm) => farm.id == currentFarm?.value)?.initials ?? "";
  }

  function getAreaLabel(): string {
    return areaFilter
      ? areaFilter.initials
      : allAreas?.find((area) => area.id === currentArea?.value)?.initials ??
      "";
  }

  const createPayload = () => {
    if (task.type !== "DETACHED_TASK") {
      const reqPayload = {
        ...payload,
        changes: [
          {
            micro_area_id: currentMicroArea?.value,
            assigned_user_id: currentResponsible?.value,
          },
        ],
      };
      setPayload(reqPayload);
    } else {
      const reqPayload = {
        ...payload,
        changes: [
          {
            farm_id: currentFarm?.value,
            assigned_user_id: currentResponsible?.value,
            task_details: taskDetails,
          },
        ],
      };
      setPayload(reqPayload);
    }
  };

  useEffect(() => {
    setAllFieldsFilled(!(!currentFarm || !currentResponsible || !currentArea || !currentMicroArea));

    if (task.body === "NO_PAGE") {
      createPayload();
    }

    if (task.type === 'DETACHED_TASK') {
      return setAllFieldsFilled(!(!currentFarm || !currentResponsible || (taskDetails.length < 8)));
    }

    if (task.type === 'PRODUCT_INCOMING') {
      axios.get(`/products`)
        .then(({ data }) => {
          if (data.length == 0)
            setNeedProduct(true)
        })
      return setAllFieldsFilled(!(!currentFarm || !currentResponsible));
    }
  }, [
    currentArea,
    currentFarm,
    currentMicroArea,
    currentResponsible,
    taskDetails,
  ]);

  const startTaskCreation = () => {
    if (task.type != "PRODUCT_INCOMING") {
      if (!currentFarm || !currentArea) return;
      setTaskPage(true);
      setFarmId(currentFarm.value);
      setAreaId(currentArea.value);
      if (currentResponsible) setResponsibleId(currentResponsible.value ?? 0);
      if (currentMicroArea) {
        setMicroAreaId(currentMicroArea.value ?? 0);
        setContextMicroArea(
          allMicroAreas?.find(
            (microArea) => microArea.id === currentMicroArea.value
          ) as Partial<IMicroArea>
        );
      }
    } else {
      if (!currentFarm) return;
      setFarmId(currentFarm.value);
      if (currentResponsible) setResponsibleId(currentResponsible.value ?? 0);
      setTaskPage(true);
    }
  };

  const closeModal = () => {
    onClose();
    resetContext();
  };

  function switchFilterPage(): JSX.Element {
    switch (task.type) {
      case "DETACHED_TASK":
        return (
          <>
            <div className="filters-container filters-column">
              <div className="filters-dropdown filters-dropdown__full-width">
                <DropDownSelect
                  onChange={(ev) => {
                    handleFarmChange(ev);
                  }}
                  options={farmOptions ?? []}
                  ref={farmRef}
                  placeholder="Selecionar fazenda"
                  label={getFarmLabel()}
                  defaultValue={currentFarm}
                />
              </div>
              <div className="filters-dropdown filters-dropdown__full-width">
                <DropDownSelect
                  options={allResponsibles ?? []}
                  ref={responsibleRef}
                  placeholder="Selecionar responsável"
                  label="Responsável"
                  defaultValue={currentResponsible}
                  onChange={(ev) => {
                    setCurrentResponsible(
                      allResponsibles?.find((resp) => resp.value === ev)
                    );
                  }}
                />
              </div>
            </div>
            <span className="detached-task_details-label">
              Detalhes da tarefa
            </span>
            <textarea
              className="detached-task_details-textarea"
              onChange={(ev) => {
                setTaskDetails(ev.target.value + "");
              }}
            ></textarea>
          </>
        );

      case "PRODUCT_INCOMING":
        return (
          <>
            <div className="filters-container filters-wrap">
              <div className="filters-dropdown">
                <DropDownSelect
                  onChange={(ev) => {
                    handleFarmChange(ev);
                  }}
                  options={farmOptions ?? []}
                  ref={farmRef}
                  placeholder="Selecionar fazenda"
                  label={getFarmLabel()}
                  defaultValue={currentFarm}
                />
              </div>
              <input className="inputCreateTask" value={"Depósito central"} disabled />
              <div className="filters-dropdown filters-dropdown__full-width">
                <DropDownSelect
                  options={allResponsibles ?? []}
                  ref={responsibleRef}
                  placeholder="Selecionar responsável pelo recebimento..."
                  label="Responsável"
                  defaultValue={currentResponsible}
                  onChange={(ev) => {
                    setCurrentResponsible(
                      allResponsibles?.find((resp) => resp.value === ev)
                    );
                  }}
                />
              </div>
            </div>
          </>
        );

      default:
        return (
          <>
            <div className="filters-container filters-wrap">
              <div className="filters-dropdown">
                <DropDownSelect
                  onChange={(ev) => {
                    handleFarmChange(ev);
                  }}
                  options={farmOptions ?? []}
                  ref={farmRef}
                  placeholder="Selecionar fazenda"
                  label={getFarmLabel()}
                  defaultValue={currentFarm}
                />
              </div>
              <div className="filters-dropdown">
                <DropDownSelect
                  onChange={(ev) => handleAreaChange(ev)}
                  options={areaOptions ?? []}
                  ref={areaRef}
                  label={getAreaLabel()}
                  placeholder="Selecionar retiro..."
                  defaultValue={currentArea}
                />
              </div>
              <div className="filters-dropdown">
                <DropDownSelect
                  options={responsibleOptions ?? []}
                  ref={responsibleRef}
                  placeholder="Resp."
                  label="Resp."
                  defaultValue={currentResponsible}
                />
              </div>
              <div className="filters-dropdown">
                <DropDownSearch
                  onChange={(ev) => handleMicroAreaChange(ev)}
                  defaultOption={currentMicroArea}
                  ref={microAreaRef}
                  options={microAreaOptionsFiltered()}
                  placeHolder="pesquisar pasto ou piquete..."
                />
              </div>
            </div>
            <div className="pasture-container">
              {!(fitCoords.length > 0) ? (
                <PointIcon />
              ) : (
                <StaticMapRender
                  farm={farmFilter.coords}
                  mainPolygon={drawCoords || []}
                  microAreas={
                    allMicroAreas ?
                      allMicroAreas
                        .filter(micro => currentArea ? micro.area_id == currentArea?.value : true)
                        .map(micro => micro.map_coords || []) :
                      []
                  }
                  fitCoords={fitCoords}
                  allMicroAreas={
                    allMicroAreas ?
                      allMicroAreas
                        .filter(micro => currentArea ? micro.area_id == currentArea?.value : true) :
                      []
                  }
                  setMicroAreaSelected={(e) => {
                    if (currentArea?.value != e?.area_id)
                      handleAreaChange(e?.area_id)
                    else
                      handleMicroAreaChange(e?.id)
                  }}
                />
              )}
            </div>
          </>
        );
    }
  }

  return (
    <div style={{ zIndex: 901 }}>
      {needProduct ? (
        <Modal
          visible={visible}
          contentStyle={{
            padding: "20px",
            width: "600px",
            height: "fit-content",
            borderRadius: 20,
            display: "flex",
            flexDirection: "column",
          }}
        >
          <div className="body-task">
            <div className="header-taskModal">
              <h2>Cadastre um produto!</h2>
              <RoundedCloseButton onClick={() => closeModal()} />
              <span>Você ainda não cadastrou um produto para criar essa tarefa, você pode criar através do gerenciamento do sistema.</span>
            </div>

            <footer className="buttons-container" style={{ width: "100%" }}>
              <ButtonOnlyTitle
                title="Cancelar"
                theme="light"
                onClick={() => onGoBack()}
              />
              <ButtonOnlyTitle
                title="Produtos"
                theme="info"
                onClick={() => {
                  setSystemManager("PRODUCTS");
                  onClose();
                }}
              />
            </footer>
          </div>
        </Modal>
      ) : (
        <Modal
          visible={visible}
          contentStyle={{
            padding: "50px",
            width: "650px",
            height: "fit-content",
            borderRadius: 20,
            display: "flex",
            flexDirection: "column",
          }}
        >
          <header className="task-header">
            <div className="task-wrapper">
              {task.icon}
              <h2>{task.title}</h2>
            </div>
            <RoundedCloseButton onClick={() => closeModal()} />
          </header>
          {!taskPage ? (
            <>
              <main className="task-designation">{switchFilterPage()}</main>
              <footer className="buttons-container">
                <ButtonOnlyTitle
                  title="Voltar"
                  theme="light"
                  onClick={() => onGoBack()}
                />
                {task.body !== "NO_PAGE" ? (
                  <ButtonOnlyTitle
                    title="Continuar"
                    theme="info"
                    disabled={!allFieldsFilled}
                    onClick={() => startTaskCreation()}
                  />
                ) : (
                  <ButtonOnlyTitle
                    title="Criar Tarefa"
                    theme="info"
                    disabled={!allFieldsFilled}
                    onClick={() => createTask()}
                  />
                )}
              </footer>
            </>
          ) : (
            <>
              <main className="task-border">
                <div className="task-intrinsecs">{children}</div>
              </main>
              <footer className="buttons-container">
                <ButtonOnlyTitle
                  title="Voltar"
                  theme="light"
                  onClick={() => {
                    setTaskPage(false)
                    setCurrentCoords(contextMicroArea?.map_coords || []);
                  }}
                />
                <ButtonOnlyTitle
                  title="Criar tarefa"
                  theme="info"
                  disabled={!allowTaskCreation}
                  onClick={() => createTask()}
                />
              </footer>
            </>
          )}
        </Modal>
      )}

      {showDrawOnMapModal && allMicroAreas && (
        <DrawMapElement coords={currentCoords} setCoords={setCurrentCoords} editable={editFences} isMulti />
      )}
    </div>
  );
};
