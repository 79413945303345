import { useEffect, useState } from 'react';
import Constants from '../../constants/Index';
import axios from "./../../services/axios";
import Cookies from 'js-cookie';

type DashboardProps = {
  url: string;
};

export default function Dashboard({
  url
}: DashboardProps) {

  useEffect(() => {
    document.title = Constants.TITLE_DASHBOARD;

    axios.get("/admin/report/authenticate")
      .then(({ data }) => {
        Cookies.set('grafana_session', data.grafana_session, { domain: "pastejo.com.br" });
        Cookies.set('grafana_session_expiry', data.grafana_session_expiry, { domain: "pastejo.com.br" });
      })
  }, []);

  return (
    <div style={{ width: "100%", height: "100%", position: "absolute", zIndex: 999 }}>
      <iframe
        id={"grafanaIframe"}
        src={url}
        width="100%"
        height="100%"
      />
    </div>
  );
}
