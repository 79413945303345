import { Outlet } from "react-router-dom";
import { SideBar } from "../../../components/CattleMap/SideBarMenu/Index";
import { EditOnboardingProvider } from "../../../contexts/EditOnboardingContext";
import { useFilter } from "../../../hooks/useFilter";
import { Index } from "../Maps/Index/Index";
import { ShowData } from "../ShowData";
import "./styles.css";
import { SystemManager } from "../ShowData/SystemManager";

export function PaddocksMapIndex() {
  const { sideBarOptionSelected } = useFilter();

  return (
    <section className="paddocksmap__container--template">
      <SideBar />
      <main style={{ background: "#F1F1F1" }}>
        {sideBarOptionSelected == "MAPA" ? (
          <Index>
            <Outlet />
          </Index>
        ) : (
          <SystemManager />
        )}
      </main>
    </section>
  );
}
