import React, { useEffect, useState } from "react"
import { Modal } from "../Default/Index"
import { RoundedCloseButton } from "../../Buttons/RoundedCloseButton/Index";
import { ButtonOnlyTitle } from "../../Buttons/ButtonOnlyTitle/Index";
import DaySelect from "../../DayPicker";
import { DateRange } from "react-day-picker";
import { format, subDays } from "date-fns";
import { useFilter } from "../../../hooks/useFilter";
import "./styles.css"

interface DateModalProps {
    setRange: (s: DateRange | undefined) => void
}

export const DateModal: React.FC<DateModalProps> = ({
    setRange
}) => {
    const { modalCalendarFilter, setModalCalendarFilter } = useFilter();
    const [dateBegin, setDateBegin] = useState<string>("");
    const [dateEnd, setDateEnd] = useState<string>("");
    const date = new Date();
    const defaultSelected: DateRange = {
        from: subDays(date, 5),
        to: date
    };
    const [rangeDays, setRangeDays] = useState<DateRange | undefined>(defaultSelected);

    useEffect(() => {
        var from = rangeDays?.from
        var to = rangeDays?.to
        if (from) {
            setDateBegin(format(new Date(from), `dd MMMM y`))
        }
        if (to) {
            setDateEnd(format(new Date(to), `dd MMMM y`))
        }
    }, [rangeDays]);

    return (<>
        <div style={{ zIndex: 910 }}>
            <Modal
                visible={modalCalendarFilter}
                contentStyle={{
                    padding: "60px",
                    width: "433px",
                    height: "auto",
                    borderRadius: 25,
                    position: "relative",
                    display: "flex",
                    flexDirection: "column",
                    gap: 30
                }}
            >
                <header className="success__modal-header">
                    <RoundedCloseButton onClick={() => setModalCalendarFilter(false)} />
                </header>
                <h2 className="dateTitle">Definir período</h2>

                <div className="dateFiltersContainer">
                    <DaySelect
                        range={rangeDays}
                        setRange={setRangeDays}
                    />
                    <div className="dateContainer">
                        <span>Data de início:</span>
                        <span>{dateBegin}</span>
                    </div>
                    <div className="dateContainer">
                        <span>Data de fim:</span>
                        <span>{dateEnd}</span>
                    </div>
                </div>

                <footer className="buttons-container">
                    <ButtonOnlyTitle
                        title="Cancelar"
                        theme="light"
                        onClick={() => {
                            setModalCalendarFilter(false)
                        }}
                    />
                    <ButtonOnlyTitle
                        title="Aplicar"
                        theme="info"
                        onClick={() => {
                            setRange(rangeDays)
                            setModalCalendarFilter(false)
                        }}
                    />
                </footer>
            </Modal>
        </div>
    </>)
}
