import { CSSProperties, useEffect, useRef, useState } from "react";
import { Polygon } from "react-leaflet";
import dayjs from 'dayjs'
import "./styles.css";

import {
  FlyToBounds,
  FlyToBoundsRefProps,
  IAnimationState,
} from "../../../../components/Leaflet/FlyToBounds/Index";
import { SimpleButtonClose } from "../../../../components/Modal/Contents/SimpleButtonClose/Index";
import { ButtonOnlyTitle } from "../../../../components/Buttons/ButtonOnlyTitle/Index";
import { LegendRounded } from "../../../../components/PaddocksMap/LegendRounded/Index";
import ContainerZoom1X from "../../../../components/Leaflet/ContainerZoom1X/Index";
import ContainerZoom4X from "../../../../components/Leaflet/ContainerZoom4X/Index";
import ContainerZoom2X from "../../../../components/Leaflet/ContainerZoom2X/Index";
import ContainerZoom3X from "../../../../components/Leaflet/ContainerZoom3X/Index";
import { PolylineFarm } from "../../../../components/PaddocksMap/PolylineFarm/Index";
import { BatchesIcons, ICustomBatch } from "../../../../components/PaddocksMap/BatchesIcons/Index";
import { TitleModal } from "../../../../components/Modal/Contents/TitleModal/Index";
import { LabelFarm1X } from "../../../../components/PaddocksMap/LabelFarm1X/Index";
import { TextModal } from "../../../../components/Modal/Contents/TextModal/Index";
import { AnimatedZoom } from "../../../../components/Leaflet/AnimatedZoom/Index";
import { AlertModal } from "../../../../components/Modal/Alert/Index";
import { useFilter } from "../../../../hooks/useFilter";

import { getCenterCoords } from "../../../../utils/leaflet/getCenterCoords";
import Constants from "../../../../constants/Index";
import api from "../../../../services/axios";

import { ICoords } from "../../../../@types/GoogleMaps/ICoord";
import { CustomControls } from "../../../../components/Leaflet/CustomControls/Index";


type IFarmData = {
  address: {
    city: { code: '5007109', name: 'Ribas do Rio Pardo' },
    state: string
    street: string
  },
  document: string,
  id: number,
  initials: string,
  map_coords: ICoords
  name: string
  owner_user: { id: number, name: string }
  properties: { season: { dry_initial: number, rain_initial: number } }
  state_registration: string
}

type IAreaData = {
  manager_user: { id: number, name: string },
  map_coords: ICoords,
  indoor_grazing_time: number,
  outdoor_grazing_time: number,
  id: number,
  name: string,
  initials: string,
  acreage: number,
  farm: {
    map_coords: ICoords,
    id: number,
    name: string,
    initials: string
  }
}

type IMicroAreaData = {
  acreage: number,
  area: IEntityAreaORFarm
  farm: IEntityAreaORFarm
  id: number
  initials: string
  map_coords: ICoords,
  grazing_begin: string,
  grazing_end: string,
  micro_area_route: {
    batch: { id: number, initials: string }, current_micro_area: boolean
  }
}

type IBatchesData = {
  area: IEntityAreaORFarm
  batch_capacity_rate: number
  categories: {
    category: {
      age_description: string,
      id: number,
      name: string
      type: string
    },
    month: number, quantity: number, weight: { value: number, unit: string }
  }[]
  current_micro_area: { map_coords: ICoords, id: number, initials: string, acreage: number }
  farm: IEntityAreaORFarm
  id: number
  initials: string
  micro_area_route: { map_coords: ICoords, id: number, initials: string, acreage: number }[]
}

type IEntityAreaORFarm = { map_coords: ICoords, id: number, name: string, initials: string }

const contentStyleAlert = {
  width: "440px",
  height: "218px",
  borderRadius: "20px",
  overflow: "hidden",
  zIndex: 999,
} as CSSProperties;

export function PaddockTime() {
  const { farmFilter, areaFilter, defaultBGFarm, setHideMicroAreaFilter, setHideBatchFilter } = useFilter();

  const flytoBoundsRef = useRef<FlyToBoundsRefProps>();
  const [stateOfAnimation, setStateOfAnimation] = useState<IAnimationState>("end");
  const [farms, setFarms] = useState<IFarmData[]>([]);
  const [areas, setAreas] = useState<IAreaData[]>([]);
  const [microAreas, setMicroAreas] = useState<IMicroAreaData[]>([]);
  const [batches, setBatches] = useState<ICustomBatch[]>([]);
  const [errorModal, seterrorModal] = useState<boolean>(false);

  function overrideCentermap() {
    setStateOfAnimation("start");
    if (flytoBoundsRef && flytoBoundsRef.current && farmFilter)
      flytoBoundsRef.current?.flyToBounds(farmFilter?.coords);
  }

  const toggleAlert = () => seterrorModal((visible) => !visible);

  const getFarmsAllFarmsByUser = () => {
    api.get<IFarmData[]>('/farms/user')
      .then(({ data: farms }) => setFarms(farms))
      .catch(toggleAlert)
  }

  const getFarmsAllAreasByUser = () => {
    api.get<IAreaData[]>('/areas/user')
      .then(({ data: areas }) => setAreas(areas))
      .catch(toggleAlert)
  }

  const getFarmsAllMicroAreasByUser = () => {
    api.get<IMicroAreaData[]>('/micro-areas/user')
      .then(({ data: microAreas }) => setMicroAreas(microAreas))
      .catch(toggleAlert)
  }

  const getFarmsAllBatchesByUser = () => {
    api.get<IBatchesData[]>('/batches/user')
      .then(({ data: batches }) => {
        const response = batches.map(e => ({
          initials: e.initials,
          coords: getCenterCoords(e.current_micro_area.map_coords),
          id: e.id.toString(),
          farmId: e.farm.id.toString(),
          areaId: e.area.id.toString(),
          visible: true
        }) as ICustomBatch)
        setBatches(response)
      })
      .catch(toggleAlert)
  }

  function handleBGOppacity(farm: IFarmData, area: IEntityAreaORFarm) {
    if (areaFilter && area.id.toString() !== areaFilter.id.toString()) return 0.2;
    if (farm.id.toString() !== farmFilter.id.toString()) return 0.2;
    return 0.55;
  }

  const handleCurrentColor = (microArea: IMicroAreaData) => {
    const { indoor_grazing_time, outdoor_grazing_time } = areas.find(f => f.id.toString() === microArea.area.id.toString()) as IAreaData
    const { grazing_begin, grazing_end, micro_area_route } = microArea
    // SE NÃO EXISTIR UM ROTA DE PASTEJO É PORQUE O PASTO NÃO É DE NENHUMA ROTA
    if (!micro_area_route) return Constants.PADDOCKTIME_COLORS.WHITE
    const date = new Date()

    //GUARDA O VALOR MAXIMO DE DIAS, PARA O PASTO, COM OU SEM GADO
    const grazingTime = grazing_begin ?? grazing_end

    const dayInMillisenconds = 86400
    const intervalDays = dayInMillisenconds * (!!grazing_begin ? indoor_grazing_time : outdoor_grazing_time)
    const maxEntityRange = dayjs(grazingTime).unix() + intervalDays // MAIOR INTERVALO DE DIAS POSSÍVEL
    const minEntityRange = dayjs(grazingTime).unix() // MENOR INTERVALO DE DIAS POSSÍVEL
    const today = dayjs(date.toDateString()).unix() // DATA ATUAL

    if (!!grazing_begin) {
      if (minEntityRange <= today && today <= maxEntityRange)
        return Constants.PADDOCKTIME_COLORS.GREEN
      return Constants.PADDOCKTIME_COLORS.RED
    }
    if (minEntityRange <= today && today <= maxEntityRange)
      return Constants.PADDOCKTIME_COLORS.ORANGE
    return Constants.PADDOCKTIME_COLORS.BLUE
  }

  useEffect(() => {
    getFarmsAllFarmsByUser()
    getFarmsAllAreasByUser()
    getFarmsAllMicroAreasByUser()
    getFarmsAllBatchesByUser()
    setHideMicroAreaFilter(true)
    setHideBatchFilter(true)
  }, [])

  useEffect(() => {
    if (flytoBoundsRef && flytoBoundsRef.current && farmFilter)
      flytoBoundsRef.current?.flyToBounds(farmFilter?.coords);
  }, [farmFilter])

  return (
    <div className="defaultmap__container">
      <AlertModal visible={errorModal} animation="slideUp" contentStyle={contentStyleAlert}>
        <section className="defaultmap__alert--content">
          <SimpleButtonClose onClick={toggleAlert} />
          <div className="defaultmap__alert--texts">
            <TitleModal>{Constants.MESSAGE_INVALID_MODAL_TITLE}</TitleModal>
            <TextModal>{Constants.MESSAGE_INVALID_MODAL_FILTER}</TextModal>
          </div>
          <ButtonOnlyTitle onClick={toggleAlert} title="voltar" theme="info" />
        </section>
      </AlertModal>

      <FlyToBounds ref={flytoBoundsRef} onStateAnimation={(state) => setStateOfAnimation(state)}>
        {farms.map(farm => {
          return <AnimatedZoom key={farm.id} coordsToBoundZoom={farm.map_coords} updateZoomVisualization={stateOfAnimation}>
            <ContainerZoom1X>
              <LabelFarm1X
                key={farm.initials}
                position={getCenterCoords(farm.map_coords)}
                initials={farm.initials}
              />
            </ContainerZoom1X>

            <ContainerZoom2X>
              <Polygon
                key={farm.id}
                pathOptions={{ fillColor: defaultBGFarm }}
                positions={farm.map_coords}
                color={Constants.PADDOCKTIME_COLORS.WHITE}
                fillOpacity={0.55}
                fill={true}
                weight={2}
                noClip
              />
            </ContainerZoom2X>

            <ContainerZoom3X>
              <PolylineFarm path={farm.map_coords} />
              <BatchesIcons batches={batches.filter((f) => f.farmId === farm.id.toString())} />
              {areas
                .filter((microArea) => microArea.farm.id === farm.id)
                .map((area) => (
                  <Polygon
                    key={area.id}
                    pathOptions={{
                      fillOpacity: handleBGOppacity(farm, area),
                      color: Constants.PADDOCKTIME_COLORS.WHITE,
                      fillColor: Constants.PADDOCKTIME_COLORS.ORANGE,
                    }}
                    color={Constants.PADDOCKTIME_COLORS.WHITE}
                    positions={area.map_coords}
                    fill={true}
                    weight={2}
                    noClip
                  />
                ))}
            </ContainerZoom3X>

            <ContainerZoom4X>
              <PolylineFarm path={farm.map_coords} />
              <BatchesIcons batches={batches.filter((f) => f.farmId === farm.id.toString())} />
              {microAreas
                .filter((microArea) => microArea.farm.id === farm.id)
                .map((microArea) => (
                  <Polygon
                    key={microArea.id}
                    pathOptions={{
                      fillOpacity: handleBGOppacity(farm, microArea.area),
                      color: Constants.PADDOCKTIME_COLORS.WHITE,
                      fillColor: handleCurrentColor(microArea),
                    }}
                    positions={microArea.map_coords}
                    fill={true}
                    weight={2}
                    noClip
                  />
                )
                )}
            </ContainerZoom4X>
          </AnimatedZoom>
        })}

      </FlyToBounds>
      <CustomControls overrideCentermap={overrideCentermap} />
      <section className="paddocktime__footerlegend--container">
        <h6>TEMPO NO PASTO</h6>
        <div className="paddocktime__footerlegend--content">
          <LegendRounded label="adequado" bgColor={Constants.PADDOCKTIME_COLORS.GREEN} />
          <LegendRounded label="descanso" bgColor={Constants.PADDOCKTIME_COLORS.ORANGE} />
          <LegendRounded label="muito tempo com gado" bgColor={Constants.PADDOCKTIME_COLORS.RED} />
          <LegendRounded label="muito tempo sem gado" bgColor={Constants.PADDOCKTIME_COLORS.BLUE} />
        </div>
      </section>
    </div>
  );
}
