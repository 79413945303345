import { useEffect, useRef, useState } from "react";
import "./styles.css";

import axios from "../../../services/axios";

import {
  DropDownSearch,
  DropDownSearchFilterProps,
  DropDownSearchFilterRefProps,
} from "../../Filters/DropDownSearch/Index";
import { DropDownFilterRefProps, DropDownSelect } from "../../Filters/DropDown/Index";

import { DropDownSelectProps } from "../../../@types/app/DropDownFilters/IDropDownSelect";
import { IOptionProps, IOptionsProps } from "../../../@types/reactSelect/IOption";
import { IFarm, IFarms, OwnerUserProps } from "../../../@types/API/IFarm";
import { IMicroAreas } from "../../../@types/API/IMicroArea";
import { IArea, IAreas } from "../../../@types/API/IArea";
import { IBatches } from "../../../@types/API/IBatches";
import { useFilter } from "../../../hooks/useFilter";
import { sortBy } from "../../../utils/arrays/sortBy";
import { useAuth } from "../../../hooks/useAuth";
import { useNavigate } from "react-router-dom";
import { getFarmsAsOptions } from "../../../utils/farms/farmsAsOptions";
import { getAreasAsOptions } from "../../../utils/farms/areasAsOptions";
import { getMicroAreasAsOptions } from "../../../utils/farms/microAreasAsOptions";
import { useTaskCreation } from "../../../hooks/useTaskCreation";

interface IDropDownFarmsProps extends Omit<DropDownSelectProps, "options"> {
  getFarmsFiltereds?: (farms: IFarms) => void; // Devolve as fazendas após a seleção do usuário
  overrideOnChange?: (id: string) => void; // Sobrescreve o médoto onChange das fazendas
  getFarmsData?: (farms: IFarms) => void; // Devolve todas as fazendas vindas da API
  options?: IOptionsProps; // Define manualmente as opções a serem exibidas
}

interface IOwnerFarmProps {
  getCurrentOwnerUser?: (owner: OwnerUserProps | undefined) => void; // Devolve o atual responável da fazenda
}

interface IDropDownAreaProps extends Omit<DropDownSelectProps, "options"> {
  getAreasFilteredsOnlyIds?: (areas: Array<string>) => void; // Devolve os retiros filtrados na seleção
  getAreasFiltereds?: (areas: IAreas) => void; // Devolve os retiros filtrados na seleção
  overrideOnChange?: (id: string) => void; // Sobrescreve o médoto onChange das fazendas
  getAreasData?: (areas: IAreas) => void; // Devolve todos os retiros vindos da API
}

interface IMicroAreasProps extends Omit<DropDownSearchFilterProps, "options"> {
  getMicroAreasFilteredsOnlyIds?: (microIds: Array<string>) => void; // Devolve os ids filtrados na seleção
  getMicroAreasFiltereds?: (microAreas: IMicroAreas) => void; // Devolve os pastos filtrados na seleção
  exportDefaultOption?: (option: IOptionProps | undefined) => void; // Devolve a ultima opção escolhida no filtro
  getMicroAreasData?: (microAreas: IMicroAreas) => void; // Devolve todos pastos vindos da API
  overrideOnChange?: (id: string | undefined) => void; // Sobrescreve o médoto onChange das fazendas
}

interface IBatchesProps extends Omit<DropDownSearchFilterProps, "options"> {
  getBatchesFilteredsOnlyIds?: (batchesIds: Array<string>) => void; // Devolve os ids filtrados na seleção
  overrideOnChange?: (id: string | undefined) => void; // Sobrescreve o médoto onChange das fazendas
  exportDefaultOption?: (option: IOptionProps | undefined) => void; // Devolve a ultima opção escolhida no filtro
  getBatchesFiltereds?: (batches: IBatches) => void; // Devolve os lotes filtrados na seleção
  getBatchesData?: (batches: IBatches) => void; // Devolve todos os lotes vindos da API
}

type FilterDataPolygonsProps = {
  searchMicroAreaProps?: IMicroAreasProps; // Propriedades para o filtro de pastos
  dropdownFarmProps?: IDropDownFarmsProps; // Propriedades para o filtro de Fazenda
  dropdownAreaProps?: IDropDownAreaProps; // Propriedades para o filtro de retiros
  ownerFarmProps?: IOwnerFarmProps; // Propriedades para o responsável da Fazenda
  searchLotsProps?: IBatchesProps; // Propriedades para o filtro de lotes
  throwAnyError?: (error: any) => void; // Função genérica para estouro de erros
  hideMicroAreas?: boolean;
  hideLots?: boolean;
};

export function FilterDataPolygons(props: FilterDataPolygonsProps) {
  const {
    hideMicroAreas = false,
    searchMicroAreaProps,
    dropdownAreaProps,
    dropdownFarmProps,
    hideLots = false,
    searchLotsProps,
    ownerFarmProps,
    throwAnyError,
  } = props;
  const { farmFilter, areaFilter } = useFilter();
  const { setAllFarms } = useTaskCreation()

  // propriedades do dropdown de fazenda
  const [farmProps, setFarmProps] = useState<IDropDownFarmsProps | undefined>(dropdownFarmProps);
  const [currentFarm, setCurrentFarm] = useState<IFarm | undefined>(farmFilter || undefined);
  const [farmOptions, setFarmOptions] = useState<IOptionsProps>([]);
  const [farms, setFarms] = useState<IFarms>([]);

  // propriedades da busca por pastos
  const [microAreaProps] = useState<IMicroAreasProps | undefined>(searchMicroAreaProps);
  const [microAreasOptions, setMicroAreasOptions] = useState<IOptionsProps>([]);
  const [microAreas, setMicroAreas] = useState<IMicroAreas>([]);
  const [defaultMicroArea, setDefaultMicroArea] = useState<IOptionProps | undefined>(undefined);
  const microAreasRef = useRef<DropDownSearchFilterRefProps>();

  // propriedades do dropdown de retiro
  const [areaProps, setAreaProps] = useState<IDropDownAreaProps | undefined>(dropdownAreaProps);
  const [currentArea, setCurrentArea] = useState<IArea | undefined>(areaFilter || undefined);
  const [areasOptions, setAreasOptions] = useState<IOptionsProps>([]);
  const [areas, setAreas] = useState<IAreas>([]);
  const areasRef = useRef<DropDownFilterRefProps>();

  // propriedades da busca por lotes
  const [batchesProps] = useState<IMicroAreasProps | undefined>(searchLotsProps);
  const [batchesOptions, setBatchesOptions] = useState<IOptionsProps>([]);
  const [batches, setBatches] = useState<IBatches>([]);
  const [defaultBatch, setDefaultbatch] = useState<IOptionProps | undefined>(undefined);
  const batchesRef = useRef<DropDownSearchFilterRefProps>();

  // [INICIO] - funções manipuladoras para o dropdown de fazenda
  function handleReturnFarmsFiltereds(farms: IFarms, idFilter?: string) {
    if (dropdownFarmProps) {
      const { getFarmsFiltereds } = dropdownFarmProps;
      getFarmsFiltereds && getFarmsFiltereds(farms.filter((f) => f.id === idFilter));
    }
  }
  async function getFarmByID(idFilter: string, farmsArray?: IFarms) {
    return await new Promise<IFarm | undefined>((resolve) => {
      resolve((farmsArray || farms).find((f) => f.id === idFilter));
    });
  }

  function handleWhenFarmChange(id: string) {
    if (dropdownFarmProps && currentFarm?.id !== id) {
      const { overrideOnChange, onChange, getFarmsFiltereds } = dropdownFarmProps;
      if (overrideOnChange) overrideOnChange(id);
      else {
        const farmFounded = farms.find((farm: any) => farm.id === id);
        setCurrentFarm(farmFounded);
        setFarmProps((props) => ({ ...props, label: farmFounded?.initials }));
        onChange && onChange(id);
        handleReturnFarmsFiltereds(farms, id);
        if (areasRef && areasRef.current) {
          areasRef.current?.resetDropDown()
          setAreasOptions([])
        };
        if (microAreasRef && microAreasRef.current) {
          microAreasRef.current?.resetDropDownFilter()
          setMicroAreasOptions([])
        };
        if (batchesRef && batchesRef.current) {
          batchesRef.current?.resetDropDownFilter()
          setBatchesOptions([])
        };
      }
    }
  }
  async function initFarms(farms: IFarms) {
    setFarms(farms);
    const options = getFarmsAsOptions(farms);
    const [farm] = options;
    const defaultPosition = dropdownFarmProps?.defaultValue;
    setFarmOptions(() => options);
    const farmID =
      defaultPosition && typeof defaultPosition !== "string" ? defaultPosition.value : farm.value;
    getFarmByID(farmID, farms)
      .then((farm) => {
        if (farm) {
          setCurrentFarm(() => farm);
          handleReturnFarmsFiltereds(farms, farm.id);
          setFarmProps((props) => ({
            ...props,
            defaultValue: { label: farm.name, value: farm.id } as IOptionProps,
          }));
        } else throw new Error("Erro no filtro de");
      })
      .catch((error) => throwFilterErrors(error));
  }
  // Isso atualiza o componente de fazenda
  useEffect(() => {
    if (currentFarm) {
      setFarmProps((props) => ({
        ...props,
        label: currentFarm?.initials,
      }));
      // Isso vem das funções manipuladoras do responsável da fazenda
      handleCurrentOwneruser(currentFarm);
      initAreas();
      initMicroAreas(currentFarm?.id);
    }
  }, [currentFarm]);
  // Isso devolve os dados de fazenda atualizados
  useEffect(() => {
    if (dropdownFarmProps) {
      const { getFarmsData } = dropdownFarmProps;
      getFarmsData && getFarmsData(farms);
    }
  }, [farms]);
  // [FIM] - funções manipuladoras para o dropdown de fazenda

  // propriedades do prorietário da fazenda
  const [currentOwnerUser, setCurrentOwnerUser] = useState<OwnerUserProps | undefined>(undefined);
  // [INICIO] - funções manipuladoras prorietário da fazenda
  function handleCurrentOwneruser(currentFarm: IFarm) {
    setCurrentOwnerUser(currentFarm.owner_user);
  }
  useEffect(() => {
    if (ownerFarmProps) {
      const { getCurrentOwnerUser } = ownerFarmProps;
      getCurrentOwnerUser && getCurrentOwnerUser(currentOwnerUser);
    }
  }, [currentOwnerUser]);
  // [FIM] - funções manipuladoras prorietário da fazenda
  


  // [INICIO] - funções manipuladoras do dropdown de retiro
  function handleReturnAreasFiltereds(areas: IAreas, idFilter?: string) {
    if (dropdownAreaProps && currentArea?.id !== idFilter) {
      const { getAreasFiltereds, getAreasFilteredsOnlyIds } = dropdownAreaProps;
      const areasFiltereds = areas.filter((f) => f.id === idFilter);
      getAreasFiltereds && getAreasFiltereds(areasFiltereds);
      getAreasFilteredsOnlyIds && getAreasFilteredsOnlyIds(areasFiltereds.map((a) => a.id as string));
    }
  }
  function handleWhenAreaChange(areaId: string) {
    if (dropdownAreaProps) {
      const { overrideOnChange } = dropdownAreaProps;
      if (overrideOnChange) overrideOnChange(areaId);
      else {
        const area = areas.find((a) => a.id == areaId);
        setAreaProps((props) => ({
          ...props,
          label: area?.initials,
        }));
        setCurrentArea(() => area);
        handleReturnAreasFiltereds(areas, area?.id);
        const labelInitials = `${currentFarm?.initials || ""}${area ? `-${area?.initials}-` : "-"}`;
        setMicroAreasOptions(() => getMicroAreasAsOptions(microAreas, area?.id, labelInitials));
        initBatches(area?.id, labelInitials);
        if (microAreasRef && microAreasRef.current) microAreasRef.current.resetDropDownFilter();
        if (batchesRef && batchesRef.current) batchesRef.current.resetDropDownFilter();
      }
    }
  }
  async function getAPIAreas(farmId?: string) {
    return await axios
      .get("areas", { params: { farm_id: farmId || currentFarm?.id } })
      .then(({ data }) => {
        const areas: IAreas = data;
        if (areas.length > 0) return areas;
        else throw new Error("Vetor de areas vazio");
      })
      .catch((error) => {
        throwFilterErrors(error);
        return undefined;
      });
  }

  async function initAreas() {
    const areas = await getAPIAreas();
    if (areas) {
      setAreasOptions(() => getAreasAsOptions(areas).sort(sortBy("label")));
      setAreas(areas);
    }
  }
  useEffect(() => {
    if (dropdownAreaProps) {
      const { getAreasData } = dropdownAreaProps;
      getAreasData && getAreasData(areas);
    }
  }, [areas]);
  // [FIM] - funções manipuladoras do dropdown de retiro



  // [INICIO] - funções manipuladoras da busca por pastos
  function handleWhenMicroAreaChange(microId: string | undefined) {
    if (searchMicroAreaProps) {
      const { overrideOnChange, getMicroAreasFiltereds, getMicroAreasFilteredsOnlyIds } =
        searchMicroAreaProps;
      if (overrideOnChange) overrideOnChange(microId);
      else {
        const microAreaFounded = microAreas.filter((m) => m.id === microId);
        getMicroAreasFiltereds && getMicroAreasFiltereds(microAreaFounded);
        getMicroAreasFilteredsOnlyIds &&
          getMicroAreasFilteredsOnlyIds(microAreaFounded.map(({ id }) => id as string));
      }
      // microAreasRef && microAreasRef.current?.resetDropDownFilter();
    }
  }
  
  async function getAPIMicroAreas(farmId?: string) {
    return await axios
      .get("micro-areas", { params: { farm_id: farmId } })
      .then(({ data }) => {
        const microAreas: IMicroAreas = data;
        if (microAreas.length > 0) return microAreas;
        else throw new Error("Vetor de micro areas vazio");
      })
      .catch((error) => {
        throwFilterErrors(error);
        return undefined;
      });
  }
  async function initMicroAreas(farmId?: string) {
    const microAreasResp = await getAPIMicroAreas(farmId);
    if (microAreasResp) {
      setMicroAreas(() => microAreasResp);
    }
  }
  function exportDefaultOptionMicroAreas(option: IOptionProps | undefined) {
    if (searchMicroAreaProps) {
      const { exportDefaultOption } = searchMicroAreaProps;
      exportDefaultOption && exportDefaultOption(option);
    }
  }
  useEffect(() => {
    if (searchMicroAreaProps) {
      const { defaultOption } = searchMicroAreaProps;
      setDefaultMicroArea(defaultOption);
    } else setDefaultMicroArea(undefined);
  }, [searchMicroAreaProps]);

  useEffect(() => {
    if (searchMicroAreaProps) {
      const { getMicroAreasData: getMicroData } = searchMicroAreaProps;
      getMicroData && getMicroData(microAreas);
    }
  }, [microAreas]);
  // [FIM] - funções manipuladoras da busca por pastos


  
  // [INICIO] - funções manipuladoras da busca por lotes
  function getBatchesAsOptions(batchesArray: IBatches, areaId: string | undefined, label?: string) {
    let filtereds: IOptionsProps = [];
    if (areaId) {
      filtereds = batchesArray
        .filter((b) => b.area.id === areaId)
        .map((b) => ({ label: `${label ? label : ""}${b.initials}`, value: b.id }));
    }
    setBatchesOptions(() => filtereds.sort(sortBy("label")));
  }
  function handleWhenBatchesChange(batchId: string | undefined) {
    if (searchLotsProps) {
      const { overrideOnChange, getBatchesFiltereds, getBatchesFilteredsOnlyIds } = searchLotsProps;
      if (overrideOnChange) overrideOnChange(batchId);
      else {
        const batchesFounded = batches.filter((m) => m.id === batchId);
        getBatchesFiltereds && getBatchesFiltereds(batchesFounded);
        getBatchesFilteredsOnlyIds &&
          getBatchesFilteredsOnlyIds(batchesFounded.map(({ id }) => id as string));
      }
    }
  }
   async function getAPIBatches(areaId?: string) {
    return await axios
      .get("batches", { params: { area_id: areaId } })
      .then(({ data }) => {
        const batches: IBatches = data;
        if (batches.length > 0) return batches;
        else throw new Error("Vetor de micro areas vazio");
      })
      .catch((error) => {
        throwFilterErrors(error);
        return undefined;
      });
  }
  async function initBatches(areaId?: string, label?: string) {
    const batches = await getAPIBatches(areaId);
    if (batches) {
      setBatches(() => batches);
      getBatchesAsOptions(batches, areaId, label);
    }
  }
  function exportDefaultOptionBatches(option: IOptionProps | undefined) {
    if (searchLotsProps) {
      const { exportDefaultOption } = searchLotsProps;
      exportDefaultOption && exportDefaultOption(option);
    }
  }
  useEffect(() => {
    if (searchLotsProps) {
      const { getBatchesData } = searchLotsProps;
      getBatchesData && getBatchesData(batches);
    }
  }, [batches]);
  useEffect(() => {
    if (searchLotsProps) {
      const { defaultOption } = searchLotsProps;
      setDefaultbatch(defaultOption);
    } else setDefaultbatch(undefined);
  }, [searchLotsProps]);
  // [FIM] - funções manipuladoras da busca por lotes

  // Função genérica para controle e estouro de erros
  const throwFilterErrors = (error: any) => throwAnyError && throwAnyError(error);
  const {user} = useAuth();
  const navigate = useNavigate();

  useEffect(() => {
    axios
      .get("farms/user")
      .then(({ data }) => {
        const farmsData: IFarms = data;
        if (farmsData.length > 0){
           initFarms(farmsData);
           setAllFarms(data)
          }
        else if(user.role=='MASTER') navigate("/bemvindo/onboarding")
        else throw new Error("Vetor de fazendas vazio");
      })
      .catch((error) => throwFilterErrors(error));
  }, [, user]);

  return (
    <section className="filterpolygons__container">
      {/* [INICIO] - Componente de Fazenda */}
      <div>
        {farmProps && <DropDownSelect {...farmProps} onChange={handleWhenFarmChange} options={farmOptions} />}
      </div>
      {/* [FIM] - Componente de Fazenda */}
      {/* [INICIO] - Componente de Responsável */}
      {currentOwnerUser && (
        <div>
          <div className="filterpolygons__owner--container">
            <small>responsável</small>
            <h6>{currentOwnerUser.name}</h6>
          </div>
        </div>
      )}
      {/* [FIM] - Componente de Responsável */}
      {/* [INICIO] - Componente de Retiros */}
      <div>
        {areaProps && (
          <DropDownSelect
            ref={areasRef}
            {...areaProps}
            onChange={handleWhenAreaChange}
            options={areasOptions}
          />
        )}
      </div>
      {/* [FIM] - Componente de Retiros*/}
      {/* [INICIO] - Componente de Pastos */}
      <div>
        {!hideMicroAreas && microAreaProps && (
          <DropDownSearch
            ref={microAreasRef}
            {...microAreaProps}
            onChange={handleWhenMicroAreaChange}
            options={microAreasOptions}
            defaultOption={defaultMicroArea}
            exportOption={exportDefaultOptionMicroAreas}
          />
        )}
      </div>
      {/* [FIM] - Componente de Pastos*/}
      {/* [INICIO] - Componente de Lotes */}
      <div>
        {!hideLots && batchesProps && (
          <DropDownSearch
            ref={batchesRef}
            {...batchesProps}
            onChange={handleWhenBatchesChange}
            options={batchesOptions}
            exportOption={exportDefaultOptionBatches}
            defaultOption={defaultBatch}
          />
        )}
      </div>
      {/* [FIM] - Componente de Lotes */}
    </section>
  );
}
