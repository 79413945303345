import { DateRange, DayPicker } from 'react-day-picker';
import 'react-day-picker/dist/style.css';
import './styles.css'

type DaySelectProps = {
    range: DateRange | undefined;
    setRange: (s: DateRange | undefined) => void
}

export default function DaySelect({
    range,
    setRange
}: DaySelectProps) {

    return (
        <DayPicker
            mode="range"
            selected={range}
            onSelect={setRange}
            toDate={new Date()}
            showOutsideDays
            fixedWeeks
        />
    );
}