import { useEffect, useState } from "react";
import "./styles.css";

import { useNavigate } from "react-router-dom";

import { ReactComponent as ChevronRighticon } from "../../../../assets/svg/icons/chevronRight.svg";
import { ReactComponent as Circleicon } from "../../../../assets/svg/icons/circle.svg";
import { useFilter } from "../../../../hooks/useFilter";

type MenuOptionProps = {
  name: string;
  title?: string;
  withoutIcon?: boolean;
  textStyle?: "light" | "light-bold";
  open?: boolean;
  to?: string;
};

export function MenuOption({ title, withoutIcon, textStyle, open, to, name }: MenuOptionProps) {
  const navigate = useNavigate();
  const { setSideBarOptionSelected } = useFilter();

  const [selected, setSelected] = useState<boolean>(false);
  const [nameMenu] = useState<string>(name);

  useEffect(() => {
    setSelected(nameMenu === window.location.pathname);
  }, [window.location.pathname]);

  return (
    <button
      className={`sidebar_menuoption--button sidebar_menuoption${selected && "--selected"}`}
      onClick={() => {
        setSideBarOptionSelected('MAPA')
        if (to) {
          navigate(to);
        }
        if (selected)
          navigate('/mapadepastos')
      }}
    >
      <div className="iconsTest">{!withoutIcon && (selected ? <ChevronRighticon /> : <Circleicon />)}</div>
      <span id="sidebar_menuoption--text">{title}</span>
    </button>
  );
}
